import { getConversationID } from '@sbt-web/network/client';
import ErrorBoundary from '@sbt-web/error-boundary';
import { urnTransformer } from '@sbt-web/utils';
import { ItemID } from '@sbt-web/network/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ContactForm } from './components/ContactForm';
import { trackContactIntention } from './tracking';
import type { AdReplyProps } from './types';
import { getSearchValue } from './utils';
import { CategoryId } from '@sbt-web/networking';
import { getAdvertiserName, isPro } from '@shared/models/Advertiser';
import { UsernameDialogAction, UsernameDialogEvent } from '../UsernameDialog';
import { AdReplyEvent } from './events';

const AdReplyComponent = ({
  isMobile,
  userId,
  requestLogin,
  onSendReplyCallback,
  onStatusDialogChanged,
  item,
  advertiserProfile,
  shop,
}: AdReplyProps) => {
  const advertiserId = item.advertiser.userId;
  const category = item.category.id;
  const { urn, subject } = item;
  const [conversationURL, setConversationURL] = useState<string>();
  const [buyerName, setBuyerName] = useState<string | undefined>(undefined);
  const [localUserId, setLocalUserId] = useState<string>(userId);
  const [showContactForm, setShowContactForm] = useState(false);
  const adReplyWrapperElement = useRef<HTMLDivElement>(null);
  const searchValue = getSearchValue();
  const enableAttachments = item.category.id === CategoryId.OfferteLavoro;
  const isAProAdvertiser = isPro(item.advertiser.type);
  const advertiserName = getAdvertiserName(
    item.advertiser.type,
    shop,
    advertiserProfile
  );

  useEffect(() => {
    setLocalUserId(userId);
  }, [userId]);

  useEffect(() => {
    if (!localUserId) return;
    const getURLOfExistingConversation = async () => {
      const itemIdInfo = urnTransformer(item.urn);
      if (!itemIdInfo) return;
      const itemIdForMessaging: ItemID = `ad${itemIdInfo?.adId}list${itemIdInfo?.contentId}`;
      try {
        const response = await getConversationID(
          process.env.NEXT_PUBLIC_HERMES_BASE_URL,
          localUserId,
          advertiserId,
          itemIdForMessaging
        );
        if (response.body) {
          setConversationURL(
            `${process.env.NEXT_PUBLIC_AREARISERVATA_BASE_URL}/messaggi#/id/${response.body}`
          );
        }
      } catch {
        return;
      }
    };
    getURLOfExistingConversation();
  }, [advertiserId, item, localUserId]);

  const handleShowContactForm = useCallback(() => {
    if (conversationURL) return window.location.assign(conversationURL);
    window.dispatchEvent(new CustomEvent('subito:AdReply:Show'));
    setShowContactForm(true);
  }, [conversationURL]);

  useEffect(() => {
    if (!showContactForm) return;
    if (!isMobile && adReplyWrapperElement.current) {
      window.scrollTo({
        top: adReplyWrapperElement.current.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [isMobile, showContactForm]);

  const handleCheckBuyerName = (userId: string) => {
    window.dispatchEvent(
      new CustomEvent(UsernameDialogEvent.CheckBuyerName, {
        detail: {
          action: UsernameDialogAction.Contact,
          userId,
        },
      })
    );
  };

  const handleGetBuyerName: EventListener = useCallback(
    (event) => {
      if (
        event instanceof CustomEvent &&
        event.detail?.action === UsernameDialogAction.Contact
      ) {
        setBuyerName(event.detail.buyerName);
        handleShowContactForm();
      }
    },
    [handleShowContactForm]
  );

  const handleNextStep = useCallback(
    (userId: string) =>
      buyerName ? handleShowContactForm() : handleCheckBuyerName(userId),
    [buyerName, handleShowContactForm]
  );

  const openLoginInPlace = useCallback(async () => {
    const { id } = await requestLogin();

    setLocalUserId(id);

    if (id !== advertiserId) {
      handleNextStep(id);
    }
  }, [advertiserId, handleNextStep, requestLogin]);

  const handleContactButtonClick = useCallback(() => {
    trackContactIntention(item);
    if (!localUserId) return openLoginInPlace();
    handleNextStep(localUserId);
  }, [handleNextStep, item, localUserId, openLoginInPlace]);

  useEffect(() => {
    window.addEventListener(
      UsernameDialogEvent.GetBuyerName,
      handleGetBuyerName
    );

    return () => {
      window.removeEventListener(
        UsernameDialogEvent.GetBuyerName,
        handleGetBuyerName
      );
    };
  }, [handleGetBuyerName]);

  useEffect(() => {
    window.addEventListener(
      AdReplyEvent.ContactButtonClick,
      handleContactButtonClick
    );

    const closeContactForm = () => setShowContactForm(false);

    window.addEventListener(AdReplyEvent.Close, closeContactForm);

    return () => {
      window.removeEventListener(
        AdReplyEvent.ContactButtonClick,
        handleContactButtonClick
      );
      window.removeEventListener(AdReplyEvent.Close, closeContactForm);
    };
  }, [handleContactButtonClick]);

  useEffect(() => {
    onStatusDialogChanged?.(showContactForm);
  }, [showContactForm, onStatusDialogChanged]);

  const handleCloseContactForm = () => {
    window.dispatchEvent(new CustomEvent(AdReplyEvent.Cancel));
    setShowContactForm(false);
  };

  return (
    <div ref={adReplyWrapperElement}>
      {showContactForm && (
        <ContactForm
          onCloseContactForm={handleCloseContactForm}
          isMobile={isMobile}
          category={category}
          enableAttachments={enableAttachments}
          isPro={isAProAdvertiser}
          userId={localUserId}
          advertiserName={advertiserName}
          buyerName={buyerName}
          subject={subject}
          adUrn={urn}
          onSendReplyCallback={onSendReplyCallback}
          requestLogin={requestLogin}
          isModalOpened={showContactForm}
          search={searchValue}
          item={item}
        />
      )}
    </div>
  );
};

export const AdReply = (props: AdReplyProps) => {
  return (
    <ErrorBoundary>
      <AdReplyComponent {...props} />
    </ErrorBoundary>
  );
};
