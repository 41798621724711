import { FlowDialog } from '@client/components/Detail/FlowDialog';
import {
  UsernameDialogAction,
  UsernameDialogEvent,
} from '@client/components/Detail/UsernameDialog';
import parsePrice from '@client/utilities/parse-price';
import { LoginTemplate, type PublicUser } from '@sbt-web/auth';
import { useViewport } from '@sbt-web/hooks';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { AdItem, HTTPStatusCode } from '@sbt-web/networking';
import { FeatureURI, NetError } from '@sbt-web/network/types';
import { sendEventToGTM } from '@sbt-web/tracking';
import { urnTransformer } from '@sbt-web/utils';
import { getOrCreatePulse } from '@tools/tracking/utils';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useUser from '../../../contexts/UserContext';
import { AdBuyButtons } from './components/AdBuyButtons';
import { createTransaction } from '@sbt-web/network/client';

type flowType = 'unguided' | 'buynow';

export type Props = {
  hideButtons?: boolean;
  item: AdItem;
};

export const AdBuyEvent = {
  OpenOfferDialog: 'subito:AdBuy:OpenOfferDialog',
  OpenCartDialog: 'subito:AdBuy:OpenCartDialog',
};

const AdBuy = ({ item, hideButtons = false }: Props) => {
  const { isMobile } = useViewport();
  const [flowUrl, setFlowUrl] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);
  const { user, requestLogin } = useUser();

  const itemIdInfo = urnTransformer(item.urn);
  const itemIdForMessaging = `ad${itemIdInfo?.adId}list${itemIdInfo?.contentId}`;
  const { optimizely } = useContext(OptimizelySubitoContext);

  const trackClickGTM = useCallback(() => {
    window.dataLayer?.push({ ecommerce: null });
    sendEventToGTM('add_to_cart', {
      ecommerce: {
        currency: 'EUR',
        items: [
          {
            item_name: item.subject,
            item_id: itemIdForMessaging,
            price: parsePrice(item.features[FeatureURI.Price]),
            currency: 'EUR',
            item_category: item.category.friendlyName,
          },
        ],
      },
    });
  }, [item, itemIdForMessaging]);

  const trackClick = useCallback(
    (flow: flowType) => {
      const ownerId = item.advertiser.userId;

      if (ownerId) {
        getOrCreatePulse()?.queueEvent({
          type: 'Click',
          object: {
            '@type': 'UIElement',
            '@id': 'sdrn:subito:content:shipment:element:buy_ad_detail',
            inReplyTo: {
              '@id': `sdrn:subito:classified:${item.urn}`,
            },
            label: flow === 'buynow' ? 'Acquista' : 'Fai una Proposta',
          },
          target: {
            id: `sdrn:subito:user:${ownerId}`,
            '@type': 'Account',
            accountId: ownerId,
          },
        });
      }
    },
    [item]
  );

  const showLoginDialog = useCallback(async (): Promise<PublicUser> => {
    return await new Promise((resolve) =>
      requestLogin(LoginTemplate.AdBuy, resolve)
    );
  }, [requestLogin]);

  const showTransactionDialog = useCallback(
    async (action: UsernameDialogAction) => {
      let userId = user?.id;

      if (!user) {
        const u = await showLoginDialog();

        userId = u.id;
      }

      window.dispatchEvent(
        new CustomEvent(UsernameDialogEvent.CheckBuyerName, {
          detail: {
            action,
            userId,
          },
        })
      );
    },
    [showLoginDialog, user]
  );

  const openTransactionFlow = useCallback(
    async (forAction: UsernameDialogAction) => {
      try {
        const { body } = await createTransaction(
          process.env.NEXT_PUBLIC_HADES_BASE_URL,
          itemIdForMessaging,
          'ad',
          forAction === UsernameDialogAction.BuyNow
        );

        const { flowURL } = body;

        if (flowURL) {
          const fullURL = new URL(flowURL);
          fullURL.searchParams.set('entrypoint', 'adDetail');
          setFlowUrl(fullURL.toString());
          setOpenModal(true);
        }
      } catch (e) {
        if (
          e instanceof NetError &&
          e.cause.status === HTTPStatusCode.Unauthorized
        ) {
          return showLoginDialog();
        }
      }
    },
    [itemIdForMessaging, showLoginDialog]
  );

  const sendTrackings = useCallback(
    (flow: flowType) => {
      optimizely?.onReady().then(() => {
        optimizely.track(
          'Click_CTA_Acquista_or_Fai_una_Proposta_from_ad_detail'
        );
      });
      trackClick(flow);
      trackClickGTM();
    },
    [optimizely, trackClick, trackClickGTM]
  );

  const handleOfferClick = useCallback(() => {
    sendTrackings('unguided');
    showTransactionDialog(UsernameDialogAction.SendOffer);
  }, [sendTrackings, showTransactionDialog]);

  const handleBuyClick = useCallback(() => {
    sendTrackings('buynow');
    showTransactionDialog(UsernameDialogAction.BuyNow);
  }, [sendTrackings, showTransactionDialog]);

  const handleGetBuyerName: EventListener = useCallback(
    (event) => {
      if (
        event instanceof CustomEvent &&
        (event.detail?.action === UsernameDialogAction.BuyNow ||
          event.detail?.action === UsernameDialogAction.SendOffer)
      ) {
        openTransactionFlow(event.detail.action);
      }
    },
    [openTransactionFlow]
  );

  useEffect(() => {
    window.addEventListener(
      UsernameDialogEvent.GetBuyerName,
      handleGetBuyerName
    );

    return () => {
      window.removeEventListener(
        UsernameDialogEvent.GetBuyerName,
        handleGetBuyerName
      );
    };
  }, [handleGetBuyerName]);

  useEffect(() => {
    window.addEventListener(AdBuyEvent.OpenOfferDialog, handleOfferClick);
    window.addEventListener(AdBuyEvent.OpenCartDialog, handleBuyClick);

    return () => {
      window.removeEventListener(AdBuyEvent.OpenOfferDialog, handleOfferClick);
      window.removeEventListener(AdBuyEvent.OpenCartDialog, handleBuyClick);
    };
  }, [handleBuyClick, handleOfferClick]);

  return (
    <>
      {!hideButtons && (
        <AdBuyButtons
          item={item}
          userId={user?.id}
          onBuyNowButtonClick={handleBuyClick}
          onSendOfferButtonClick={handleOfferClick}
        />
      )}
      <FlowDialog
        fullscreen={isMobile}
        opened={openModal}
        onCloseIntent={() => {
          setFlowUrl('');
          setOpenModal(false);
        }}
        flowUrl={flowUrl}
      />
    </>
  );
};

export default AdBuy;
