import React, { useMemo } from 'react';
import Script from 'next/script';
import { AdItem } from '@sbt-web/networking';
import { LiraRunAdvScript } from '@sbt-web/adv';
import {
  getDesktopFallbackConfig,
  getMsiteFallbackConfig,
} from '@client/components/Adv/Lira/fallbackConfig/adView';
import { createSkyscraperSizeConstraints } from '@client/components/Adv/Lira/createSkyscraperSizeConstraints';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';
import ErrorBoundary from '@sbt-web/error-boundary';
import { targetingForAd } from '@client/components/Adv/Lira/targeting/forAd';
import { useGetVerticalByCategoryId } from '@client/components/Adv/Lira/LiraRunAdvScriptWrapper/useGetVerticalByCategoryId';

const adtest =
  process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT === 'prod' ? 'off' : 'on';

const LiraWrapper = ({ ad }: { ad: AdItem }) => {
  const vertical = useGetVerticalByCategoryId(ad.category.id);

  const fallbackConfigDesktop = useMemo(
    () => getDesktopFallbackConfig(vertical),
    [vertical]
  );
  const fallbackConfigMsite = useMemo(
    () => getMsiteFallbackConfig(vertical),
    [vertical]
  );

  const targeting = useMemo(() => targetingForAd(ad), [ad]);

  const constraintsByContainerId = useMemo(
    () => ({
      ...createSkyscraperSizeConstraints(
        LiraContainerIds.adView.skyscraperLeft,
        LiraContainerIds.adView.skyscraperRight
      ),
      [LiraContainerIds.adView.sponsorGallery]: {
        isLazyLoad: true,
      },
      [LiraContainerIds.adView.anchorGallery]: {
        isLazyLoad: true,
      },
      [LiraContainerIds.adView.imageIntoGalleryForPro]: {
        isLazyLoad: true,
      },
      [LiraContainerIds.adView.replyBox]: {
        isLazyLoad: true,
      },
      //Is lazy because on the monorepo is suspense...
      [LiraContainerIds.adView.videoPreroll]: {
        isLazyLoad: true,
      },
      // Please not under houston toggle, handle in Lira UI directly
      [LiraContainerIds.adView.imageIntoGalleryForPrivateMsite]: {
        isLazyLoad: true,
      },
      // Please not under houston toggle, handle in Lira UI directly
      [LiraContainerIds.adView.imageIntoGalleryForPrivate]: {
        isLazyLoad: true,
      },
      // Please not under houston toggle, handle in Lira UI directly
      [LiraContainerIds.adView.imageIntoGalleryForProMsite]: {
        isLazyLoad: true,
      },
      // Please not under houston toggle, handle in Lira UI directly
      [LiraContainerIds.adView.belowGallery]: {
        isLazyLoad: true,
      },
      [LiraContainerIds.adView.bottom]: {
        isLazyLoad: true,
      },
    }),
    []
  );

  return (
    <LiraRunAdvScript
      NextJsScriptComponent={Script}
      vertical={vertical}
      pageType={'adview'}
      env={process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT}
      fallbackHebeConfig={{
        msite: fallbackConfigMsite,
        desktop: fallbackConfigDesktop,
      }}
      adsense={{ adtest, query: ad.subject }}
      constraintsByContainerId={constraintsByContainerId}
      targeting={targeting}
    />
  );
};

export const LiraRunAdvScriptWrapperForAdview = ({ ad }: { ad: AdItem }) => {
  return (
    <ErrorBoundary>
      <LiraWrapper ad={ad} />
    </ErrorBoundary>
  );
};
