import { ItemFeature, ItemAdvertiser } from '@sbt-web/networking';
import { FeatureURI } from '@sbt-web/network/types';
import { evalBoolFeature, evalNumberFeature } from '@shared/models';
import { isPro } from '@shared/models/Advertiser';

const LIMIT_EUR = 2000;

type VisibilityChecker = (
  features: {
    [uri: string]: ItemFeature;
  },
  advertiser: ItemAdvertiser,
  userId?: string | null
) => boolean;

export enum TransactionFlow {
  SendOffer = 'SENDOFFER',
  BuyNow = 'BUYNOW',
}

type TransactionFlowsChecker = (
  features: {
    [uri: string]: ItemFeature;
  },
  advertiser: ItemAdvertiser,
  userId?: string | null
) => TransactionFlow[];

const isTransactionEnabled: VisibilityChecker = (
  features,
  advertiser,
  userId
) => {
  const isUserTheAdvertiser = userId == advertiser.userId;
  const isShippable = evalBoolFeature(features[FeatureURI.ItemShippable]);
  const itemTransactionState =
    features[FeatureURI.TransactionStatus]?.values[0].value;
  const itemIsAlreadyInATransaction = ['IN_PROGRESS', 'SOLD'].includes(
    itemTransactionState
  );

  return !isUserTheAdvertiser && isShippable && !itemIsAlreadyInATransaction;
};

const isSendOfferAllowed: VisibilityChecker = (features, advertiser, userId) =>
  !isPro(advertiser.type) && isTransactionEnabled(features, advertiser, userId);

const isBuyNowAllowed: VisibilityChecker = (features, advertiser, userId) => {
  const price = evalNumberFeature(features[FeatureURI.Price]);
  const isValidPrice = !!price && price > 0 && price <= LIMIT_EUR;
  const isTransactionEnabledForUser =
    isTransactionEnabled(features, advertiser, userId) && isValidPrice;

  return isPro(advertiser.type)
    ? isTransactionEnabledForUser && !!advertiser.proTransactionsEnabled
    : isTransactionEnabledForUser;
};

export const getTransactionFlows: TransactionFlowsChecker = (
  features,
  advertiser,
  userId
) => {
  const transactionFlows = [];

  if (isSendOfferAllowed(features, advertiser, userId))
    transactionFlows.push(TransactionFlow.SendOffer);
  if (isBuyNowAllowed(features, advertiser, userId))
    transactionFlows.push(TransactionFlow.BuyNow);

  return transactionFlows;
};
