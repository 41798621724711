import { type ItemFeature } from '@sbt-web/networking';
import { FeatureURI } from '@sbt-web/network/types';

// Whether or not a feature is allowed to be shown
const AllowedFeatures: Set<string> = new Set([
  FeatureURI.AirConditioning,
  FeatureURI.AllServicings,
  FeatureURI.AnimalType,
  FeatureURI.AudiovideoType,
  FeatureURI.AvailableNow,
  FeatureURI.Balcony,
  FeatureURI.Bathrooms,
  FeatureURI.BicycleType,
  FeatureURI.Bike,
  FeatureURI.BookType,
  FeatureURI.BuildingCondition,
  FeatureURI.Car,
  FeatureURI.CaravanType,
  FeatureURI.CarType,
  FeatureURI.ChildrenAge,
  FeatureURI.ChildrenType,
  FeatureURI.Cin,
  FeatureURI.ClothingGender,
  FeatureURI.ClothingNumber,
  FeatureURI.Color,
  FeatureURI.ComputerType,
  FeatureURI.Consumption,
  FeatureURI.ConsumptionSearchable,
  FeatureURI.ContractType,
  FeatureURI.CubicCapacity,
  FeatureURI.Damaged,
  FeatureURI.Degree,
  FeatureURI.Doors,
  FeatureURI.DriveWheel,
  FeatureURI.Elevator,
  FeatureURI.EnergyClass,
  FeatureURI.Floor,
  FeatureURI.Fuel,
  FeatureURI.Furnished,
  FeatureURI.Garden,
  FeatureURI.Gearbox,
  FeatureURI.Gender,
  FeatureURI.Heating,
  FeatureURI.HobbyType,
  FeatureURI.HorsePower,
  FeatureURI.ItemCondition,
  FeatureURI.JobCategory,
  FeatureURI.LastFloor,
  FeatureURI.MileageScalar,
  FeatureURI.MonthlyRent,
  FeatureURI.MotorbicycleType,
  FeatureURI.MultiLevel,
  FeatureURI.OfficeType,
  FeatureURI.PaintType,
  FeatureURI.Parking,
  FeatureURI.PhoneType,
  FeatureURI.Pollution,
  FeatureURI.Reception,
  FeatureURI.RegisterDate,
  FeatureURI.Room,
  FeatureURI.Rooms,
  FeatureURI.RoomType,
  FeatureURI.Seats,
  FeatureURI.Service,
  FeatureURI.ShipLength,
  FeatureURI.ShipType,
  FeatureURI.Size,
  FeatureURI.SmokeFree,
  FeatureURI.Smoker,
  FeatureURI.SportType,
  FeatureURI.TotalOwners,
  FeatureURI.VatDeductible,
  FeatureURI.VehicleLength,
  FeatureURI.VehicleStatus,
  FeatureURI.VehicleType,
  FeatureURI.WarrantyMonths,
  FeatureURI.WorkHours,
  FeatureURI.WorkLevel,
  FeatureURI.FashionClothingType,
  FeatureURI.FashionBrand,
  FeatureURI.FashionSize,
] satisfies FeatureURI[]);

/**
 * Returns `true` only if the feature exists and the first value key is 1
 */
export const evalBoolFeature = (feat?: ItemFeature): boolean =>
  feat?.values?.[0]?.key === '1';

export const evalNumberFeature = (feat?: ItemFeature): number | null =>
  feat?.values?.[0]?.key ? parseFloat(feat?.values?.[0].key) : null;

export function filterDetailFeatures(features: ItemFeature[]): ItemFeature[] {
  if ((features || []).length === 0) return [];

  const featuresToDisplay = features.filter(canBeShownInDetail);

  // TO-DO: remove this once every ad has a registered date
  // this is needed at the moment to work around the fact that
  // some ads might have a complete register date (e.g. 2019/02)
  // and some other might be limited to the registration year,
  // which we fall back to
  let feature = getFeature(features, FeatureURI.Year);
  if (
    !hasFeature(features, FeatureURI.RegisterDate) &&
    hasFeature(features, FeatureURI.Year) &&
    feature !== undefined
  ) {
    featuresToDisplay.push(feature);
  }

  // TO-DO: remove this once every ad has a punctual km data
  // this is needed at the moment to work around the fact that
  // some ads might have old mileage range
  // and some other might have the punctual km data,
  // which we fall back to
  feature = getFeature(features, FeatureURI.Mileage);
  if (
    !hasFeature(features, FeatureURI.MileageScalar) &&
    hasFeature(features, FeatureURI.Mileage) &&
    feature !== undefined
  ) {
    featuresToDisplay.push(feature);
  }

  return featuresToDisplay;
}

function canBeShownInDetail(feat?: ItemFeature): boolean {
  return feat != undefined && AllowedFeatures.has(feat.uri);
}

const hasFeature = (features: ItemFeature[], uri: FeatureURI): boolean =>
  features.some((f) => f?.uri === uri);

const getFeature = (
  features: ItemFeature[],
  uri: FeatureURI
): ItemFeature | undefined => features.filter((f) => f?.uri === uri)[0];
