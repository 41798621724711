import { ListingPlugin, SmartADVItem } from '@shared/models';
import { all } from '@shared/models/ListingPlugins/matchers';
import { after, before } from '@shared/models/ListingPlugins/position';
import { ADSENSE_MSITE_STYLE, GAM_MSITE_STYLE } from './commons';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';

const msiteAdvEvery4: Array<ListingPlugin> = [
  {
    model: new SmartADVItem(null, {
      source: 'lira',
      style: ADSENSE_MSITE_STYLE,
      id: LiraContainerIds.listing.aboveListing,
    }),
    categories: all(),
    position: before(1),
  },
  // --- After the first Ad
  {
    model: new SmartADVItem(null, {
      source: 'lira',
      id: LiraContainerIds.listing.native1,
      // Higher placement to reduce CLS issues with sizes: fluid or 1x1.
      // For the native placements 200px is the best value but we may have
      // CLS issues.
      style: { ...GAM_MSITE_STYLE, minHeight: '250px' },
    }),
    categories: all(),
    position: after(1),
  },
  // --- Then after three Ads
  {
    model: new SmartADVItem(null, {
      source: 'lira',
      id: LiraContainerIds.listing.box1,
      style: GAM_MSITE_STYLE,
    }),
    categories: all(),
    position: after(4),
  },
  // --- Then every 4 Ads
  {
    model: new SmartADVItem(null, {
      source: 'lira',
      style: ADSENSE_MSITE_STYLE,
      id: LiraContainerIds.listing.middle1,
      //variations: MsiteMiddle0,
      //gamFallback: MsiteGAMIds.FALLBACKMIDDLE1,
    }),
    categories: all(),
    position: after(8),
  },
  {
    model: new SmartADVItem(null, {
      source: 'lira',
      style: GAM_MSITE_STYLE,
      id: LiraContainerIds.listing.native2,
    }),
    categories: all(),
    position: after(12),
  },
  {
    model: new SmartADVItem(null, {
      source: 'lira',
      style: GAM_MSITE_STYLE,
      id: LiraContainerIds.listing.box2,
    }),
    categories: all(),
    position: after(16),
  },
  {
    model: new SmartADVItem(null, {
      source: 'lira',
      style: GAM_MSITE_STYLE,
      id: LiraContainerIds.listing.box3,
    }),
    categories: all(),
    position: after(20),
  },
  {
    model: new SmartADVItem(null, {
      source: 'lira',
      style: ADSENSE_MSITE_STYLE,
      id: LiraContainerIds.listing.middle2,
      //variations: MsiteMiddle1,
      //gamFallback: MsiteGAMIds.BOX4,
    }),
    categories: all(),
    position: after(24),
  },
  {
    model: new SmartADVItem(null, {
      source: 'lira',
      style: ADSENSE_MSITE_STYLE,
      id: LiraContainerIds.listing.middle3,
      //variations: MsiteMiddle2,
      //gamFallback: MsiteGAMIds.BOX5,
    }),
    categories: all(),
    position: after(28),
  },
];

export { msiteAdvEvery4 };
