import { NotificationsContainer } from '@sbt-web/ui';
import { NotificationsRef } from '@shared/tools/Notifications';
import classnames from 'classnames';
import React, { useEffect, useState, type PropsWithChildren } from 'react';
import Header from './Header';
import Footer from '@sbt-web/footer';

import '@sbt-web/footer/dist/index.css';
import ownClasses from './layout.module.scss';

interface Props {
  theme: string;
  pageName: string;
}

const nameToSparkTheme = new Map<string, string>([
  ['redCorporate', 'default'],
  ['blueMotori', 'motori'],
  ['yellowMarket', 'market'],
  ['purpleImmobili', 'immobili'],
  ['greenLavoro', 'lavoro'],
]);

const mainRef = React.createRef<HTMLElement>();

const Layout: React.FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  theme,
  pageName,
}) => {
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (mainRef.current) {
        if (mainRef.current?.getBoundingClientRect().top <= 0) {
          setFixed(true);
        } else {
          setFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.classList.remove(
      'theme-redCorporate',
      'theme-blueMotori',
      'theme-yellowMarket',
      'theme-purpleImmobili',
      'theme-greenLavoro'
    );

    document.body.classList.add(`theme-${theme}`);

    document.body.dataset['theme'] = nameToSparkTheme.get(theme) ?? 'default';
  }, [theme]);

  return (
    <div
      id="layout"
      className={classnames(ownClasses.wrapper, `theme-${theme}`)}
      data-theme={nameToSparkTheme.get(theme) ?? 'default'}
      data-testid="layout"
    >
      <div data-google-interstitial="false">
        <Header
          theme={theme}
          section={pageName === 'listing' ? 'listing' : 'adview'}
        />
      </div>
      <main className={ownClasses.main} ref={mainRef}>
        <div data-google-interstitial="false">
          <NotificationsContainer
            ref={NotificationsRef}
            removeAfter={3}
            max={5}
            fixed={fixed}
            size="medium"
          />
        </div>
        {children}
      </main>
      <div data-google-interstitial="false">
        <Footer
          type="full"
          assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE_URL}
        />
      </div>
    </div>
  );
};

export default Layout;
