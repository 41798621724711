import { useEffect } from 'react';
import { AdItem } from '@sbt-web/networking';
import { FeatureURI } from '@sbt-web/network/types';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';
export const useLiraLazyOverlayGalleryForPro = ({
  ad,
  isMobile,
}: {
  ad: AdItem;
  isMobile: boolean;
}): void => {
  useEffect(() => {
    let slots: Array<string> = [];
    if (isMobile) {
      slots.push(LiraContainerIds.adView.anchorGallery);
    }
    if (
      !ad?.features?.[FeatureURI.ItemShippingCostTuttoSubito] &&
      ad?.advertiser?.type === 1 &&
      ad?.images?.length > 0
    ) {
      if (isMobile) {
        slots.push(LiraContainerIds.adView.imageIntoGalleryForProMsite);
      } else {
        slots = [
          LiraContainerIds.adView.sponsorGallery,
          LiraContainerIds.adView.imageIntoGalleryForPro,
        ];
      }
    }
    if (slots.length > 0) {
      window.liraSDKQueue?.push(() => {
        window.liraSdk?.fetchLazyLoadAd(slots);
      });
    }
  }, [ad?.advertiser?.type, ad?.features, ad?.images?.length, isMobile]);
};
