'use client';

import {
  getPublicUser,
  LoginInPlace,
  LoginTemplate,
  type PublicUser,
} from '@sbt-web/auth';
import { useViewport } from '@sbt-web/hooks';
import { captureException } from '@sentry/nextjs';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';

type AfterLoginCallback = (user: PublicUser) => void;

/**
 * There are four states:
 * 1. The app is mounting and the context is new, so the user is `null`.
 * 2. The Provider is just mounted, but the effect has
 * not run for the user and the user remains `null`.
 * 3. The effect has run and the user is not logged in, so `user` remains `null`.
 * 4. The effect has run and the user is logged in, so `user` is defined; or the
 * user has just logged in, so `user` is defined.
 */
interface ContextProps {
  /**
   * The logged-in user, as read from the cookie.
   * This is the source of truth since it will update components when changed.
   * The value will never be `undefined`, only `null` or a `PublicUser` in
   * line with `getPublicUser`.
   **/
  user: PublicUser | null;
  /** Triggers the Login in place flow for the given template and calling back */
  requestLogin: (template: LoginTemplate, callback: AfterLoginCallback) => void;
}

const UserContext = createContext<ContextProps>({
  user: null,
  requestLogin: () => undefined,
});

type LoginInPlaceUI =
  | { isOpen: false }
  | {
      isOpen: true;
      template: LoginTemplate;
      afterLoginCallback: AfterLoginCallback;
    };

const closedUI: LoginInPlaceUI = Object.freeze({ isOpen: false });

const setDataLayerUser = (user: PublicUser) => {
  window.subito?.dataLayer?.push({
    user: {
      id: user.id,
      email: user.email,
    },
  });
};

const UserProvider = (props: PropsWithChildren) => {
  const { isMobile } = useViewport();
  const [user, setUser] = useState<PublicUser | null>(null);
  const [lipUI, setLipUI] = useState<LoginInPlaceUI>(closedUI);

  const requestLogin = useCallback(
    (template: LoginTemplate, callback: AfterLoginCallback) =>
      setLipUI({ isOpen: true, template, afterLoginCallback: callback }),
    [setLipUI]
  );

  useEffect(() => {
    const user = getPublicUser();
    if (user) {
      setUser(user);
    }
  }, []);

  useEffect(() => {
    if (user !== null) {
      setDataLayerUser(user);
    }
  }, [user]);

  const closeModal = () => setLipUI(closedUI);

  // Memoized in order to avoid re-renders unless there is a real change in the context
  const contextValue = useMemo(
    () => ({ user, requestLogin }),
    [user, requestLogin]
  );

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
      <LoginInPlace
        hashID="login-in-place-new"
        onCloseIntent={closeModal}
        onEnter={() => undefined}
        onExit={closeModal}
        onLogin={
          lipUI.isOpen
            ? (user) => {
                if (user === null) {
                  const e = new TypeError(
                    'User details are null after a login in place'
                  );

                  captureException(e);

                  return;
                }

                setLipUI(closedUI);
                lipUI.afterLoginCallback(user);
                setUser(user);
              }
            : () => undefined
        }
        isMobile={isMobile}
        reservedAreaBase={process.env.NEXT_PUBLIC_AREARISERVATA_BASE_URL}
        template={lipUI.isOpen ? lipUI.template : undefined}
        visited={lipUI.isOpen}
        enableNewPage
      />
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

export default useUser;
export { UserProvider };
