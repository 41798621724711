import { SmartADVItem } from '@shared/models';
import type { Vertical } from '@sbt-web/adv';

const fromMacroCategoryToVertical = (macroCategory: string): Vertical => {
  const market = ['elettronica', 'casa-e-persona', 'sport-hobby', 'vari'];
  if (market.includes(macroCategory)) {
    return 'market';
  }
  if (macroCategory === 'lavoro') {
    return 'jobs';
  }
  if (macroCategory === 'motori') {
    return 'motors';
  }
  if (macroCategory === 'immobili') {
    return 'realestate';
  }
  return 'subito';
};

const isThisPlacementEnabled = (
  item: SmartADVItem,
  expectedVariation: string | null
): boolean => {
  if (expectedVariation) {
    return item.experimentOnly === expectedVariation;
  }

  return !item.experimentOnly;
};

export { fromMacroCategoryToVertical, isThisPlacementEnabled };
