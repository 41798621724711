import {
  AdItem,
  CategoryId,
  CategoryStore,
  ItemFeature,
} from '@sbt-web/networking';
import { FeatureURI, FilterURI } from '@sbt-web/network/types';
import { MacroCategoryIds, Targeting } from '@sbt-web/adv';
import { macroFromCategoryId } from '@shared/helpers/Themes';
import { mapPackToItemFeatures } from '@shared/helpers/PackFeatures';
import parsePrice from '@client/utilities/parse-price';
import { evalBoolFeature } from '@shared/models';

export const targetingForAd = (ad: AdItem): Targeting => {
  const categoryId = ad.category.id;
  const macroCategory = macroFromCategoryId(categoryId);
  const parentCategory = CategoryStore.getCategoryById(ad.category.parentId);

  const features = mapPackToItemFeatures(ad.features);
  const price = parsePrice(features[FeatureURI.Price])?.toString();
  const targeting: Targeting = {
    macroCategoryId: macroCategory.id as MacroCategoryIds,
    categoryName: ad.category.label,
    parentCategoryName: parentCategory?.label,
    region: ad.geo?.region?.value,
    province: ad.geo?.city?.value,
    town: ad.geo?.town?.value,
    queryString: ad.subject,
    itemShippable: evalBoolFeature(features[FeatureURI.ItemShippable]),
    priceFrom: price,
    priceTo: price,
    type: buildType(features),
    adType: ad.type.key as Targeting['adType'],
    advertiserId: ad.advertiser.userId,
    advertiserType: ad.advertiser.type === 0 ? 'freemium' : 'pro',
  };

  if (macroCategory.id === CategoryId.Motori) {
    const brandFilter =
      categoryId === CategoryId.Moto ? FilterURI.BikeBrand : FilterURI.CarBrand;
    const modelFilter =
      categoryId === CategoryId.Moto ? FilterURI.BikeModel : FilterURI.CarModel;
    const mileage = features[FilterURI.CarMileage]?.values?.[0].value.replace(
      /\./g,
      ''
    );
    targeting.brand = features[brandFilter]?.values?.[0].value;
    targeting.model = features[modelFilter]?.values?.[0].value;
    targeting.fuel = features[FilterURI.CarFuel]?.values?.[0].value;
    targeting.regDate = features[FilterURI.CarYear]?.values?.[0].value;
    targeting.carType = features[FilterURI.CarType]?.values?.[0].value;
    targeting.gearbox = features[FilterURI.CarGear]?.values?.[0].value;
    targeting.emission = features[FeatureURI.Pollution]?.values?.[0].value;
    targeting.vehicleStatus =
      features[FeatureURI.VehicleStatus]?.values?.[0].value;
    targeting.qualitySeal =
      features[FilterURI.CarQualitySeal]?.values?.[0].value;
    targeting.minMileage = mileage?.split('-')[0];
    targeting.maxMileage = mileage?.split('-')[1];
  } else if (macroCategory.id === CategoryId.Immobili) {
    targeting.room = features[FilterURI.RealEstateRoom]?.values?.[0].value;
  } else if (macroCategory.id === CategoryId.LavoroServizi) {
    targeting.jobCategory = features[FilterURI.JobsCategory]?.values?.[0].value;
    targeting.contractType =
      features[FilterURI.JobsContractType]?.values?.[0].value;
    targeting.workLevel = features[FilterURI.JobsWorkLevel]?.values?.[0].value;
    targeting.degree = features[FilterURI.JobsDegree]?.values?.[0].value;
  }

  return targeting;
};

const buildType = (features: Record<string, ItemFeature>) => {
  return (
    features[FeatureURI.ContractType]?.values?.[0].value ||
    features[FeatureURI.Service]?.values?.[0].value ||
    features[FeatureURI.ServiceType]?.values?.[0].value ||
    features[FeatureURI.ComputerType]?.values?.[0].value ||
    features[FeatureURI.AudiovideoType]?.values?.[0].value ||
    features[FeatureURI.PhoneType]?.values?.[0].value ||
    features[FeatureURI.HobbyType]?.values?.[0].value ||
    features[FeatureURI.BicycleType]?.values?.[0].value ||
    features[FeatureURI.SportType]?.values?.[0].value ||
    features[FeatureURI.BookType]?.values?.[0].value ||
    features[FeatureURI.AnimalType]?.values?.[0].value ||
    features[FeatureURI.CarType]?.values?.[0].value ||
    features[FeatureURI.ShipType]?.values?.[0].value ||
    features[FeatureURI.CaravanType]?.values?.[0].value ||
    features[FeatureURI.VehicleType]?.values?.[0].value ||
    features[FeatureURI.MotorbicycleType]?.values?.[0].value ||
    features[FeatureURI.OfficeType]?.values?.[0].value ||
    features[FeatureURI.RoomType]?.values?.[0].value ||
    features[FeatureURI.ClothingType]?.values?.[0].value
  );
};
