import { getUserAdInfo } from '@sbt-web/network/client';
import type { UserAdInfo } from '@sbt-web/network/types';
import { AnubisClient, type SearchData } from '@sbt-web/networking';
import { getCategoryByID } from '@sbt-web/utils';
import { unwrap } from '@shared/helpers/Promise';
import { useEffect, useState } from 'react';
import type { AdInfoForUser } from './types';

interface LoadDataResponse {
  userAdInfo?: UserAdInfo;
  search?: SearchData;
}

const loadData = async function (
  userId: string
): Promise<LoadDataResponse | undefined> {
  const GIAPETE_TIMEOUT = 3000;
  const anubisClient = new AnubisClient(
    process.env.NEXT_PUBLIC_HADES_BASE_URL,
    'web'
  );

  const [searchResponse, userAdInfo] = await Promise.allSettled([
    anubisClient.search({ uid: userId, lim: 1 }),
    getUserAdInfo(
      process.env.NEXT_PUBLIC_HADES_BASE_URL,
      userId,
      GIAPETE_TIMEOUT
    ),
  ]);

  const userAdInfoData = unwrap(userAdInfo);
  const searchData = unwrap(searchResponse);

  return {
    userAdInfo: userAdInfoData?.body,
    search: searchData?.search,
  };
};

const createMessage = function (date?: string): string | null {
  if (!date) {
    return null;
  }

  const epochTimestamp = Date.parse(date);

  if (Number.isNaN(epochTimestamp)) {
    console.error(`The date ${date} is an invalid date`);

    return null;
  }

  const messageDate = new Date(epochTimestamp);

  const monthAndYear = messageDate.toLocaleDateString('it-IT', {
    year: 'numeric',
    month: 'long',
  });

  return `Pubblica da ${monthAndYear}`;
};

export function useInfo(userId: string): AdInfoForUser {
  const [infoComponent, setInfoComponent] = useState<AdInfoForUser>({
    categories: null,
    message: null,
    numberOfAdsOnline: null,
    numberOfAdsPublished: null,
  });

  useEffect(() => {
    let isRemoved = false;

    (async () => {
      const data = await loadData(userId);
      if (!isRemoved && data) {
        setInfoComponent({
          message: createMessage(data.userAdInfo?.firstAdInsertionDate),
          numberOfAdsPublished: data.userAdInfo?.totalApprovedAds ?? null,
          categories: (data.userAdInfo?.userCategoriesInfo ?? []).map(
            (catInfo) => {
              return {
                category: getCategoryByID(catInfo.category),
                approvedAds: catInfo.approvedAds,
              };
            }
          ),
          numberOfAdsOnline: data.search?.total ?? null,
        });
      }
    })();

    return () => {
      isRemoved = true;
    };
  }, [userId]);

  return infoComponent;
}
