import { AdItem, GenericItem } from '@sbt-web/networking';
import { CSSProperties } from 'react';
import { ListingPlugin } from './ListingPlugins';

export function extractAdItems<I extends DecoratedItem>(items: I[]): AdItem[] {
  return items.map((decorated) => decorated.item);
}

interface CustomAttributesItem {
  customAttributes: { [key: string]: string };
}

export type SmartPlacementSpecification = {
  source: 'lira';
  id: string;
  variations?: never;
  gamFallback?: never;
  style?: CSSProperties;
};
export class SmartADVItem implements GenericItem {
  kind = 'SmartADVItem' as const;
  constructor(
    public desktop: SmartPlacementSpecification | null,
    public msite: SmartPlacementSpecification | null,
    public experimentOnly?: string
  ) {}
}

export class CriteoItem implements GenericItem, CustomAttributesItem {
  kind = 'CriteoItem' as const;
  constructor(
    public tagId: string,
    public style?: CSSProperties,
    public customAttributes = {}
  ) {}
}

export class CardBlockItem implements GenericItem, CustomAttributesItem {
  kind = 'CardBlockItem' as const;
  constructor(
    public tagId: string,
    public customAttributes = {}
  ) {}
}

export class GalleryPlaceholder implements GenericItem {
  kind = 'GalleryPlaceholder' as const;
}

export class GalleryItem implements GenericItem {
  kind = 'GalleryItem' as const;
  constructor(public adItem: AdItem) {}
}

export class GeoboostItem implements GenericItem {
  kind = 'GeoboostItem' as const;
  boostedItems: Array<AdItem> = [];
  constructor() {}
}

export class GalleryCTAItem implements GenericItem {
  kind = 'GalleryCTAItem' as const;
  constructor() {}
}

export class ExpandSearchItem implements GenericItem {
  kind = 'ExpandSearchItem' as const;
}

export class DecoratedItem implements GenericItem {
  kind = 'DecoratedItem' as const;
  constructor(
    public before: ListingPlugin[],
    public item: AdItem,
    public after: ListingPlugin[]
  ) {}
}

export type Item =
  | AdItem
  | SmartADVItem
  | CriteoItem
  | CardBlockItem
  | GalleryPlaceholder
  | GalleryCTAItem
  | GalleryItem
  | DecoratedItem
  | ExpandSearchItem
  | GeoboostItem;
