import {
  FavoritesError,
  FavoritesErrorPayload,
  FavoritesProvider,
} from '@sbt-web/favorites';
import { HTTPStatusCode } from '@sbt-web/networking';
import { assertNever } from '@sbt-web/utils';
import { LoginTemplate } from '@sbt-web/auth';
import { sendNotification } from '@shared/tools/Notifications';
import React from 'react';
import { getDataLayer, getItem, getOrCreatePulse } from '@tools/tracking/utils';
import { HADES_PATH } from '@shared/constants';
import useUser from '@client/contexts/UserContext';

export interface FavProps {
  onUnsave?: (urn: string) => void;
  onSave?: (urn: string) => void;
  onEngage?: (urn: string) => void;
  children: React.ReactNode;
}

const FavoritesContainer = ({
  onUnsave,
  onSave,
  onEngage,
  children,
}: FavProps) => {
  const { user, requestLogin } = useUser();

  const checkUnauthRequestLogin = (
    data: FavoritesErrorPayload,
    cb?: () => void
  ): boolean => {
    if (
      !user ||
      (!(data instanceof Error) &&
        data.response?.payload.code === HTTPStatusCode.Unauthorized)
    ) {
      requestLogin(LoginTemplate.Favorites, () => {
        if (data.urn && getItem(getDataLayer(), data.urn)) {
          getOrCreatePulse()?.queueEvent({
            type: 'Login',
            object: {
              id: 'login-inplace',
              type: 'Confirmation',
              items: getItem(getDataLayer(), data.urn),
            },
            target: {
              id: 'login-inplace',
              type: 'Confirmation',
            },
            origin: {
              description: 'view-form-save-classified-ad',
            },
          });
        } else {
          if (!data.urn) {
            throw new Error('"adUrn" not found and is required');
          } else if (!getItem(getDataLayer(), data.urn)) {
            throw new Error(
              `Ad with urn ${data.urn} not found in dataLayer ads`
            );
          }
        }

        cb?.();
      });
      return false;
    }
    return true;
  };

  const handleFavoritesError = (
    err: FavoritesError,
    data: FavoritesErrorPayload,
    retrier?: () => void
  ) => {
    switch (err) {
      case FavoritesError.Save:
        return (
          checkUnauthRequestLogin(data, retrier) &&
          sendNotification(
            "Impossibile aggiungere l'annuncio ai preferiti",
            'alert'
          )
        );
      case FavoritesError.Unsave:
        return (
          checkUnauthRequestLogin(data, retrier) &&
          sendNotification(
            "Impossibile rimuovere l'annuncio dai preferiti",
            'alert'
          )
        );
      case FavoritesError.Sync:
      case FavoritesError.Unknown:
        // We don't want to explicitly warn the user about this error
        return;
      default:
        assertNever(err);
    }
  };

  return (
    <FavoritesProvider
      APIBaseUrl={HADES_PATH}
      APIChannel="web"
      onUnsave={onUnsave}
      onSave={onSave}
      onError={handleFavoritesError}
      onEngage={onEngage}
      userId={user?.id ?? ''}
    >
      {children}
    </FavoritesProvider>
  );
};

export default FavoritesContainer;
