import { PublicUser } from '@sbt-web/auth';
import Header from '@sbt-web/header';
import '@sbt-web/header/style';
import type { ENV } from '@sbt-web/utils';
import { houstonEnvMap } from '@shared/constants';
import { getOrCreatePulse } from '@tools/tracking/utils';
import React, { type FunctionComponent } from 'react';
import useUser from '../../contexts/UserContext';

interface Props {
  section: 'adview' | 'listing';
  theme: string;
}

const themeMap = new Map<string, string>([
  ['blueMotori', 'motori'],
  ['redCorporate', 'corporate'],
  ['greenLavoro', 'lavoro'],
  ['purpleImmobili', 'immobili'],
  ['yellowMarket', 'market'],
]);

/**
 * A constant for the unlogged user.
 * Using a constant reference might save re-renders.
 */
const emptyUser = new PublicUser('', '', '');

const HeaderComponent: FunctionComponent<Props> = ({ section, theme }) => {
  const headerTheme = themeMap.get(theme) ?? 'corporate';

  const { user } = useUser();

  return (
    <Header
      section={section}
      theme={headerTheme}
      urlPrefixes={{
        assetsBase: process.env.NEXT_PUBLIC_ASSETS_BASE_URL,
        baseURL: process.env.NEXT_PUBLIC_ENV_BASE_URL,
        phpBase: process.env.NEXT_PUBLIC_PHP_BASE_URL,
        reservedArea: process.env.NEXT_PUBLIC_AREARISERVATA_BASE_URL,
        shopBase: process.env.NEXT_PUBLIC_IMPRESAPIU_BASE_URL,
      }}
      user={user ?? emptyUser}
      env={houstonEnvMap.get(
        process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT as ENV
      )}
      pulseInstance={getOrCreatePulse()}
    />
  );
};

export default HeaderComponent;
