import { DataLayerBridge } from '@client/bridges/DataLayer';
import {
  markPerformance,
  Marks,
} from '@client/utilities/performance/performanceMonitor';
import { initPresencePing } from '@client/utilities/presence-ping';
import { getPublicUser } from '@sbt-web/auth';
import { initDidomi } from '@sbt-web/didomi';
import type { AdItem } from '@sbt-web/networking';
import queueTask from 'post-task';
import { sendViewClassifiedAdEvent } from '@tools/tracking/AdViewTracking';
import { getOrCreatePulse } from '@tools/tracking/utils';
import { useEffect } from 'react';

type Params = {
  ad: AdItem;
  trustPingEnabled?: boolean;
};

const useAdDetailCommons = ({ ad, trustPingEnabled }: Params) => {
  useEffect(() => {
    markPerformance(Marks.AD_DETAILS_USE_EFFECT);
    new DataLayerBridge('adview', ad).register(getPublicUser());

    // Sending the tracking event to pulse after the
    // data layer initialization.
    // This **MUST** come after DataLayerBridge.register()
    queueTask(() => {
      sendViewClassifiedAdEvent();
    }, 'background');

    if (trustPingEnabled) {
      queueTask(() => {
        initPresencePing();
      }, 'user-visible');
    }
  }, [ad, trustPingEnabled]);

  useEffect(() => {
    initDidomi(getOrCreatePulse(), 'addetail');
  }, []);
};

export { useAdDetailCommons };
