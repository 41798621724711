import AdBuy from '@client/components/Detail/AdBuy';
import { AdReply } from '@client/components/Detail/AdReply';
import AdvertiserInfo from '@client/components/Detail/AdvertiserInfo';
import { IncreaseVisibilityButton } from '@client/components/Detail/IncreaseVisibilityButton';
import { ReplyTime } from '@client/components/Detail/TrustInfo/ReplyTime';
import UsernameDialog from '@client/components/Detail/UsernameDialog';
import { LoginTemplate } from '@sbt-web/auth';
import useUser from '../../../../contexts/UserContext';
import ErrorBoundary from '@sbt-web/error-boundary';
import { useViewport } from '@sbt-web/hooks';
import { AdItem, ProfileQueryData } from '@sbt-web/networking';
import { FeatureURI } from '@sbt-web/network/types';
import {
  getTransactionFlows,
  TransactionFlow,
} from '@shared/tools/transaction/utils';
import classnames from 'classnames';
import React, { useState } from 'react';
import { AdReplyButtons } from '../../AdReply/components/AdReplyButtons';
import { getShowPhone } from '@tools/phoneNumber/utils';
import type { TrustProfileInfo, Shop } from '@sbt-web/network/types';
import ownClasses from './style/advertiser-info-section.module.scss';
import { AdsAdDetailsBlock } from '@client/components/Adv/AdSense/adDetails/AdDetailsBlock';

interface Props {
  classes?: string[];
  item: AdItem;
  shop: Shop | null;
  advertiserProfile: ProfileQueryData | null;
  trustInfo: TrustProfileInfo | null;
}

export type { Props };

export const AdvertiserInfoSection = ({
  classes = [],
  shop,
  advertiserProfile,
  item,
  trustInfo,
}: Props) => {
  const { isMobile } = useViewport();
  const { user, requestLogin } = useUser();
  const [isContactFormOpened, setIsContactFormOpened] = useState(false);
  const userId = user?.id ?? '';

  const transactionFlows = getTransactionFlows(
    item.features,
    item.advertiser,
    userId
  );
  const isBuyNowVisible = transactionFlows.includes(TransactionFlow.BuyNow);

  const userIsSeller = userId === item.advertiser.userId;
  const transactionStatus: string | undefined =
    item.features[FeatureURI.TransactionStatus]?.values[0].value;
  const isSold = transactionStatus != undefined;
  const thisIsMySoldAd = isSold && userIsSeller;
  const thereIsPlaceForContactButton = transactionFlows.length < 2;
  const isOnlyOneTransactionFlowsVisible = transactionFlows.length === 1;
  const showPhone = getShowPhone(item, userId);
  const shouldShowReplyTimeText =
    !userIsSeller && trustInfo?.replyTimeText != null;

  return (
    <>
      <section className={classnames(...classes)}>
        <div className={classnames(ownClasses['seller-info-content'])}>
          <AdvertiserInfo
            item={item}
            shop={shop}
            advertiserProfile={advertiserProfile}
            trustInfo={trustInfo}
            showContactButton={isBuyNowVisible}
          />
          {!isMobile && !thisIsMySoldAd && (
            <div
              className={classnames(
                'sticky-cta-bottom-anchor',
                ownClasses['action-buttons']
              )}
            >
              {!isContactFormOpened && (
                <div className={classnames(ownClasses['buttons-box'])}>
                  {userIsSeller ? (
                    <IncreaseVisibilityButton
                      isMobile={isMobile}
                      adUrn={item.urn}
                    />
                  ) : (
                    <>
                      {trustInfo?.replyTimeText ? (
                        <ReplyTime text={trustInfo?.replyTimeText} />
                      ) : null}
                      {thereIsPlaceForContactButton && (
                        <AdReplyButtons
                          adUrn={item.urn}
                          isMobile={isMobile}
                          contactAsSecondaryButton={
                            isOnlyOneTransactionFlowsVisible
                          }
                          showPhone={showPhone}
                          item={item}
                        />
                      )}
                      <AdBuy item={item} />
                    </>
                  )}
                </div>
              )}
              <AdReply
                item={item}
                isMobile={false}
                requestLogin={() => {
                  return new Promise((resolve) => {
                    requestLogin(LoginTemplate.AdReply, resolve);
                  });
                }}
                userId={userId}
                onStatusDialogChanged={setIsContactFormOpened}
                advertiserProfile={advertiserProfile}
                shop={shop}
              />
            </div>
          )}
          {isMobile && <AdsAdDetailsBlock isMobile={isMobile} />}
        </div>
      </section>

      {isMobile && !thisIsMySoldAd && (
        <section
          className={classnames(
            'sticky-cta-bottom-anchor',
            ownClasses['stickiness'],
            {
              [ownClasses['with-reply-time']]: shouldShowReplyTimeText,
            },
            ...classes
          )}
        >
          <div className={classnames(ownClasses['buttons-box'])}>
            {userIsSeller ? (
              <IncreaseVisibilityButton isMobile={isMobile} adUrn={item.urn} />
            ) : (
              <>
                {thereIsPlaceForContactButton && (
                  <AdReplyButtons
                    adUrn={item.urn}
                    isMobile={isMobile}
                    contactAsSecondaryButton={isOnlyOneTransactionFlowsVisible}
                    showPhone={showPhone}
                    item={item}
                  />
                )}
                <AdBuy item={item} />
              </>
            )}
          </div>
          {shouldShowReplyTimeText && (
            <ReplyTime text={trustInfo?.replyTimeText} />
          )}
          <AdReply
            item={item}
            isMobile={true}
            requestLogin={() => {
              return new Promise((resolve) => {
                requestLogin(LoginTemplate.AdReply, resolve);
              });
            }}
            userId={userId}
            onStatusDialogChanged={setIsContactFormOpened}
            advertiserProfile={advertiserProfile}
            shop={shop}
          />
        </section>
      )}

      <UsernameDialog />
    </>
  );
};

const WrapperComponent = (props: Props) => (
  <ErrorBoundary>
    <AdvertiserInfoSection {...props} />
  </ErrorBoundary>
);

export default WrapperComponent;
