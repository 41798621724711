import React, { useState, useEffect } from 'react';
import { AdItem } from '@sbt-web/networking';

import { ContactButton } from './ContactButton';
import { PhoneButton } from './PhoneButton';
import { getSearchValue } from '../../utils';

import classes from './index.module.scss';
import useUser from '@client/contexts/UserContext';

export type AdReplyButtonsProps = {
  isMobile: boolean;
  contactAsSecondaryButton?: boolean;
  adUrn: string;
  showPhone: boolean;
  item: AdItem;
};

export const AdReplyButtons = ({
  isMobile,
  contactAsSecondaryButton,
  adUrn,
  showPhone,
  item,
}: AdReplyButtonsProps) => {
  const { user } = useUser();
  const [hasMounted, setHasMounted] = useState(false);
  const searchValue = getSearchValue();

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return (
      <>
        <div aria-hidden="true" className={classes.skeleton} />
        <span
          role="status"
          aria-live="polite"
          className={classes.visuallyHidden}
        >
          Caricamento pulsanti di contatto in corso
        </span>
      </>
    );
  }

  if (!user && showPhone) {
    return (
      <div className={classes.switchedTouchPoints}>
        <ContactButton isSecondary={true} showLabel={false} showIcon={true} />
        <PhoneButton
          adUrn={adUrn}
          isMobile={isMobile}
          search={searchValue}
          item={item}
          buttonLabelDesktop="Mostra numero"
          buttonLabelMobile="Mostra numero"
          buttonDesign="solid"
          buttonSize="large"
          pulseEvent={{
            name: 'Mostra Numero',
            pageType: 'ad_detail',
          }}
        />
      </div>
    );
  }

  return (
    <>
      {showPhone && (
        <PhoneButton
          adUrn={adUrn}
          isMobile={isMobile}
          search={searchValue}
          item={item}
          buttonDesign="outline"
          buttonSize="large"
          pulseEvent={{
            name: 'Mostra Numero',
            pageType: 'ad_detail',
          }}
        />
      )}
      <ContactButton isSecondary={contactAsSecondaryButton} />
    </>
  );
};
