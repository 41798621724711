import { AdTypes, Orders } from '@sbt-web/networking';

export const adTypesToSlugMap: Record<AdTypes, string> = {
  [AdTypes.Sell]: 'vendita',
  [AdTypes.Rent]: 'affitto',
  [AdTypes.Seek]: 'cerco',
  [AdTypes.VacationRent]: 'affitto-vacanze',
  [AdTypes.GiveAway]: 'regalo',
};

export const slugToAdTypeMap: Record<string, AdTypes> = {
  vendita: AdTypes.Sell,
  affitto: AdTypes.Rent,
  cerco: AdTypes.Seek,
  'affitto-vacanze': AdTypes.VacationRent,
  regalo: AdTypes.GiveAway,
};

export const adTypesLabelsMap: Record<AdTypes, string> = {
  [AdTypes.Sell]: 'In vendita',
  [AdTypes.Seek]: 'Cercasi',
  [AdTypes.Rent]: 'In affitto',
  [AdTypes.VacationRent]: 'In affitto per vacanze',
  [AdTypes.GiveAway]: 'In regalo',
};

export const adTypesShortLabelsMap: Record<AdTypes, string> = {
  [AdTypes.Sell]: 'Vendita',
  [AdTypes.Seek]: 'Cercasi',
  [AdTypes.Rent]: 'Affitto',
  [AdTypes.VacationRent]: 'Affitto',
  [AdTypes.GiveAway]: 'Regalo',
};

export const ordersFullLabelsMap: Record<Orders, string> = {
  [Orders.Relevance]: 'Dal più rilevante',
  [Orders.DateDesc]: 'Dal più recente',
  [Orders.PriceAsc]: 'Dal meno caro',
  [Orders.PriceDesc]: 'Dal più caro',
  [Orders.YearAsc]: 'Per anno crescente',
  [Orders.YearDesc]: 'Per anno decrescente',
  [Orders.MileageAsc]: 'Con meno chilometri',
  [Orders.MileageDesc]: 'Con più chilometri',
};

export const QSOLabel = 'Cerca solo nel titolo';

export const shippableOnlyLabel = 'Solo annunci con spedizione disponibile';
export const urgentLabel = 'Solo annunci urgenti';

export enum AdvertiserType {
  Private = 0,
  ImpresaPiu = 1,
  Company = 2,
}
